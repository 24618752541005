import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DBExercise, ExerciseStatsState, Diff } from 'types/ExerciseTypes'
import { AnswerStat } from 'types/StatisticTypes'
import {
	DefaultContentQuestionState,
	FillContentQuestionState,
	LongContentQuestionState,
	DefaultAudioBuffer,
	FillAudioBuffer
} from 'types/QuestionTypes'
import exerciseService from 'services/exercises'
import { generateHint } from 'utils/helpers'
import { Answer } from 'types/AnswerTypes'


interface CurrentExerciseState {
	loading: boolean,
	exercise: DBExercise
	length: number
	stats: ExerciseStatsState
	currentQuestion: DefaultContentQuestionState | FillContentQuestionState // | LongContentQuestionState
	currentAudioBuffer: DefaultAudioBuffer | FillAudioBuffer
	recordedAudio: any
	writtenAnswer: string
	chosenAnswer: Answer
}

export const startExercise = createAsyncThunk(
	'user/startExercise',
	async ({ id, answerFormat }: { id: string, answerFormat: string }, thunkAPI) => {
		try {
			const exercise = await exerciseService.getById(id)
			exercise['answerFormat'] = answerFormat
			// shuffle the questions if the setting is on
			if (exercise.settings.shuffleQuestions) {
				const shuffledQuestions = exercise.questions.sort(() => Math.random() - 0.5)
				exercise.questions = shuffledQuestions
			}
			return exercise
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get exercise: ${e.response.data}`)
		}
	}
)

const initialState: CurrentExerciseState = {
	loading: false,
	exercise: {
		id: '',
		title: {
			text: '',
			audio: '',
			slowAudio: ''
		},
		description: {
			slp: {
				text: '',
				audio: '',
				slowAudio: ''
			},
			patient: {
				text: '',
				audio: '',
				slowAudio: ''
			}
		},
		langCode: '',
		type: [],
		skill:[],
		subtype: [],
		answerFormat: '',
		category: '',
		isAdult: false,
		template: '',
		tags: [],
		difficulty: 0,
		settings: {
			hasImage: false,
			useHint: false,
			isPublic: false,
			shuffleQuestions: false
		},
		questions: [],
		owner: '',
		createdAt: ''
	},
	length: 0,
	stats: {
		numCorrect: 0,
		// how many ambiguous answers (empty transcript/low confidence score) are accepted before moving on.
		// This has to be updated also in nextQuestion
		tries: 3,
		answerStatus: '',
		status: 'description',
		confidence: null,
		index: 0,
		timer: {
			start: '',
			exercise: 0,
			recording: 0
		},
		answers: [],
		coins: 0,
		questionCoins: 0,
		retry: true,
		diff: []
	},
	currentQuestion: {
		id: '',
		langCode: '',
		type: [],
		subtype: [],
		category: '',
		template: '',
		tags: [],
		difficulty: 0,
		settings: {
			hasImage: false,
			isPublic: false,
			useHint: false,
			shuffleQuestions: false
		},
		owner: '',
		createdAt: '',
		contentFormat: '',
		answerFormat: '',
		answers: [],
		audio: {
			question: '',
			answers: [],
			sentence: '',
			fullSentence: ''
		},
		// Properties specific to fill question
		start: '',
		end: '',
		sentence: {
			text: '',
			audio: '',
			slowAudio: ''
		}
	},
	currentAudioBuffer: {
		question: [],
		answers: [[]],
		hint: []
	},
	recordedAudio: null,
	writtenAnswer: '',
	chosenAnswer: {
		answer: {
			text: '',
			audio: '',
			slowAudio: ''
		},
		isCorrect: false,
		format: '',
		audio: [] },
}

export const currentExerciseSlice = createSlice({
	name: 'currentExercise',
	initialState: initialState,
	reducers: {
		changeExerciseLoading: (state:CurrentExerciseState, action:PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		clearCurrentExercise: (state:CurrentExerciseState) => {
			state.exercise = initialState.exercise
			state.length = initialState.length
			state.stats = initialState.stats
			state.currentQuestion = initialState.currentQuestion
		},
		setExerciseStartTime: (state:CurrentExerciseState, action:PayloadAction<string>) => {
			state.stats.timer.start = action.payload
		},
		nextQuestion: (state:CurrentExerciseState) => {
			state.stats.index += 1
			state.stats.tries = 3
			state.stats.retry = true
			state.stats.questionCoins = 0
			state.stats.diff = []
		},
		increaseCoins: (state:CurrentExerciseState, action:PayloadAction<number>) => {
			state.stats.coins += action.payload
			state.stats.questionCoins = action.payload
		},
		decreaseNumTries: (state:CurrentExerciseState) => {
			state.stats.tries -= 1
		},
		changeRetry: (state:CurrentExerciseState) => {
			state.stats.retry = false
		},
		setNumTries: (state:CurrentExerciseState, action:PayloadAction<number>) => {
			state.stats.tries = action.payload
		},
		increaseNumCorrect: (state:CurrentExerciseState) => {
			state.stats.numCorrect += 1
		},
		changeAnswerStatus: (state:CurrentExerciseState, action:PayloadAction<string>) => {
			state.stats.answerStatus = action.payload
		},
		changeStatus: (state:CurrentExerciseState, action:PayloadAction<string>) => {
			// correct, repeat, retry, incorrect, description, phonology, next, feedback, finished, none            			
			state.stats.status = action.payload
		},
		changeConfidence: (state:CurrentExerciseState, action:PayloadAction<number>) => {
			const confidence = action.payload
			state.stats.confidence = confidence
		},
		changeDiff: (state:CurrentExerciseState, action:PayloadAction<Diff[]>) => {
			state.stats.diff = action.payload
		},
		setExerciseDuration: (state:CurrentExerciseState, action:PayloadAction<number>) => {
			state.stats.timer.exercise = action.payload
		},
		changeRecordingDuration: (state:CurrentExerciseState, action:PayloadAction<number>) => {
			state.stats.timer.recording += action.payload
		},
		// TODO, any neeeds to be changed here to proper state
		changeCurrentQuestion: (state:CurrentExerciseState, action:PayloadAction<number>) => {
			const questionData = state.exercise.questions[action.payload]
			state.currentQuestion = questionData
			if ('question' in questionData && questionData.template === 'default') {
				state.currentQuestion.question = questionData.question
				state.currentQuestion.hint = generateHint(questionData.answers[0].answer.text)
				state.currentQuestion.audio = {
					question: questionData.question.audio,
					answers: questionData.answers.map(answerObj => answerObj.answer.audio),
				}
				if (questionData.settings.useHint ) state.currentQuestion.audio.hint = questionData.answers[0].hint?.audio
			}
			else if ('answers' in questionData && 'start' in questionData && questionData.template === 'fill') {
				const question = questionData.sentence
				state.currentQuestion.question = question
				state.currentQuestion.fullSentence = questionData.answers[0].fullSentence
				state.currentQuestion.hint = generateHint(questionData.answers[0].answer.text)
				state.currentQuestion.audio = {
					sentence: questionData.sentence?.audio,
					answers: questionData.answers.map(answerObj => answerObj.answer.audio),
					fullSentence: questionData.answers[0].fullSentence?.audio
				}
				if (questionData.settings.useHint ) state.currentQuestion.audio.hint = questionData.answers[0].hint?.audio
			}
			state.currentAudioBuffer = initialState.currentAudioBuffer
		},
		changeAudioBuffer: (state:CurrentExerciseState, action:PayloadAction<DefaultAudioBuffer | FillAudioBuffer>) => {
			state.currentAudioBuffer = action.payload
		},
		// TODO: change the payload type when updating how audio is stored
		changeRecordedAudio: (state:CurrentExerciseState, action:PayloadAction<any>) => {
			state.recordedAudio = action.payload
		},
		changeWrittenAnswer: (state:CurrentExerciseState, action:PayloadAction<string>) => {
			state.writtenAnswer = action.payload
		},
		changeChosenAnswer: (state:CurrentExerciseState, action:PayloadAction<Answer>) => {
			state.chosenAnswer = action.payload
		},
		changeCurrentAlternatives: (state:CurrentExerciseState, action:PayloadAction<Answer[]>) => {
			state.currentQuestion.answers = action.payload
		},
		addQuestionStat: (state:CurrentExerciseState, action:PayloadAction<AnswerStat>) => {
			state.stats.answers.push(action.payload)
		},
	},
	extraReducers: (builder) => {
		builder.addCase(startExercise.fulfilled, (state:CurrentExerciseState, action:PayloadAction<DBExercise>) => {
			state.exercise = action.payload
			state.length = action.payload.questions.length
			state.stats = initialState.stats
			state.currentQuestion = initialState.currentQuestion
			state.currentAudioBuffer = initialState.currentAudioBuffer
			// Set the first question to current question
			// Not sure about this solution for dispatching action, but best I have found! Reference:
			// https://stackoverflow.com/questions/65106681/redux-toolkit-dispatch-an-action-from-an-extrareducers-listener
			const param = { payload: 0, type: 'number' }
			currentExerciseSlice.caseReducers.changeCurrentQuestion(state, param)
			state.loading = false
		})
		builder.addCase(startExercise.rejected, (state:CurrentExerciseState, action:PayloadAction<any>) => {
			console.error(action.payload)
			state.loading = false
		})
		builder.addCase(startExercise.pending, (state:CurrentExerciseState) => {
			state.loading = true
		})
	}
})

export const {
	changeExerciseLoading,
	clearCurrentExercise,
	setExerciseStartTime,
	nextQuestion,
	changeCurrentQuestion,
	changeAudioBuffer,
	changeRecordedAudio,
	changeWrittenAnswer,
	decreaseNumTries,
	increaseNumCorrect,
	changeAnswerStatus,
	changeStatus,
	changeConfidence,
	changeDiff,
	setExerciseDuration,
	changeRecordingDuration,
	changeChosenAnswer,
	setNumTries,
	changeCurrentAlternatives,
	addQuestionStat,
	changeRetry,
	increaseCoins
} = currentExerciseSlice.actions

export default currentExerciseSlice.reducer

