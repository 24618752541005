import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { getConfidenceColor } from 'utils/helpers'

const WrittenAnswer = () => {
	const answerFormat = useSelector(state => state.currentExercise.currentQuestion.answerFormat)
	const start = useSelector(state => state.currentExercise.currentQuestion.start)
	const end = useSelector(state => state.currentExercise.currentQuestion.end)
	const confidence = useSelector(state => state.currentExercise.stats.confidence)
	const diff = useSelector(state => state.currentExercise.stats.diff)

	const renderAnswer = () => {
		return (
			<>
				{answerFormat === 'fill' ? <span>{start} </span> : <></>}
				{diff.map((obj) => {
					if (!obj.added && !obj.removed) {
						return <b>{obj.value}</b>
					} else if (obj.added) {
						return <b style={{color: '#12B76A'}}>{obj.value}</b>
					}
					else if (obj.removed) {
						return <b style={{ backgroundColor: '#F04438', textDecoration: 'line-through' }}>{obj.value}</b>
					}
				})}
				{answerFormat === 'fill' ? <span> {end}</span> : <></>}
			</>
		)
	}

	return (
		<div
			className='exercise--feedback-writtenAnswer p-2 text-start'
			style={{ backgroundColor: '#FFF', borderColor: getConfidenceColor(confidence) }}
			data-cy='exercise-written-answer-feedback'
		>
			<FontAwesomeIcon className='px-3' icon={faPen} />
			{renderAnswer()}
		</div>
	)
}

export default WrittenAnswer