import { useEffect, useState, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Routes, Route, Navigate } from 'react-router-dom'
import Mixpanel from 'mixpanel-browser'
import { isChrome, isEdge, isFirefox, isSafari } from 'react-device-detect'

import { useSelector, useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import { useTranslation } from 'react-i18next'
import { userSession } from 'reducers/userSlice'
import { changeLibraryLanguage } from 'reducers/previousPageSlice'
import { userIsAdminOrSlp, userIsAdmin } from 'utils/helpers'
import { MIXPANEL_TOKEN } from 'utils/config'

import LeftNavBar from 'components/LeftNavBar/LeftNavBar'
import AddImage from 'pages/AddImagePage'
import Consent from 'components/Consent'
import BrowserPopup from 'components/BrowserPopup'
import Loading from 'components/Loading'

import CreateExercisePage from 'pages/CreateExercisePage/CreateExercisePage'
import PracticePage from 'pages/PracticePage/PracticePage'
import ExercisePage from 'pages/ExercisePage/ExercisePage'
import ComprehensionExercisePage from 'pages/ComprehensionExercisePage/ComprehensionExercisePage'
import EditExerciseListPage from 'pages/EditExerciseListPage/EditExerciseListPage'
import LoginPage from 'pages/LoginPage/LoginPage'
import ImagesPage from 'pages/ImagesPage/ImagesPage'
import ErrorFallback from 'pages/ErrorFallback'
import AdminDashboardPage from 'pages/AdminDashboardPage/AdminDashboardPage'
import CookiesPage from 'pages/CookiesPage/CookiesPage'
import CreateUserPage from 'pages/CreateUserPage'
import UserPage from 'pages/UserPage'
import PatientsListPage from 'pages/PatientsListPage/PatientsListPage'
import PatientEditPage from 'pages/PatientEditPage/PatientEditPage'
import ExercisePreviewPage from 'pages/ExercisePreviewPage'
import UserAdminPage from 'pages/UserAdminPage'
import ChatbotPage from 'pages/ChatbotPage/ChatbotPage'
import ChatbotScenarioPage from 'pages/ChatbotScenarioPage'
import './App.css'
import DownloadableExercise from 'components/DownloadableExercise/DownloadableExercise'

const App = () => {
	const [mixpanel, setMixpanel] = useState(Mixpanel)
	const [browserPopupVisible, setBrowserPopupVisible] = useState(true)

	// Redux state and dispatch init
	const currentExercise = useSelector(state => state.currentExercise.exercise)
	const editExerciseId = useSelector(state => state.newOrEditedExercise.exercise.id)
	const user = useSelector(state => state.user)
	const cookieConsent = useAppSelector(state => state.appState.cookieConsent)
	const { i18n } = useTranslation()
	const dispatch = useDispatch()

	/**
  * FE error handler called by React ErrorBoundary component
  */
	const myErrorHandler = (error, info) => {
		// Do something with the error
		console.log(info)
		console.log(error)
	}


	/**
   * Initialize mixpanel
   */
	useEffect(() => {
		if (cookieConsent){
			mixpanel.init(
				MIXPANEL_TOKEN,
				// { 'ip': false } // disables geolocation data: https://help.mixpanel.com/hc/en-us/articles/115004494803-Disable-Geolocation-Collection
			)
		}
	}, [cookieConsent])

	/**
   * Check the authentication status of the current user
   * This also re-populates the redux state (also ncessary when page is reloaded)
   * TODO: figure out how to not run 2 times on first render, if logging in. 
   * If we don't run it on first render, then session does not continue on reload..
   */
	useEffect(() => {
		dispatch(userSession())
	}, [user.status.authenticated])

	/**
	 * Set the default library language to the language selected on login
	 */
	useEffect(() => {
		dispatch(changeLibraryLanguage(i18n.language))
	}, [])

	const handleBrowserPopup = () => {
		setBrowserPopupVisible(false)
	}

	const showConsentPopup = () => {
		const browserPopup = !browserPopupVisible || isFirefox || isChrome || isEdge || isSafari
		return browserPopup && !cookieConsent
	}

	/**
   * When the page hook's value is changed updates the view content accordingly
   * @returns {JSX.element} Selected component
   */
	const pageContent = () => {
		if (user.status.authenticated === null) {
			return (
				<Route path='/login' element={<LoginPage />} />)
		}
		return (
			<>
				{/* <Route path="/register" element={<Register />} /> */}
				<Route path='/login' element={
					!user.status.authenticated
						? <LoginPage />
						: <Navigate to='/dashboard' />
				} />
				<Route path='/dashboard' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar
							page={<AdminDashboardPage />}
							fullWidth={true}
						/>
						: <Navigate to='/' />
				} />
				<Route path='/create' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<CreateExercisePage isEdit={false} />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/edit_exercises' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<EditExerciseListPage />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/edit_exercises/assign/:patientId' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<EditExerciseListPage />} />
						: <Navigate to='/edit_exercises' />
				}/>
				<Route path='/edit' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? ( editExerciseId !== ''
							? <LeftNavBar page={<CreateExercisePage isEdit={true} />} />
							: <Navigate to='/edit_exercises' />
						)
						: <Navigate to='/' />
				}/>
				<Route path='/exercises/view/:id/:renderType/:baselib' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<ExercisePreviewPage />} />
						: <Navigate to='/edit_exercises' />
				}/>
				<Route path='/exercises/download/:id/:renderType/:baselib' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<DownloadableExercise />} />
						: <Navigate to='/edit_exercises' />
				}/>
				<Route path='/add_image' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <>
							<LeftNavBar page={<AddImage />} />
						</>
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/images' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<ImagesPage />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/chatbot' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<ChatbotPage />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/chatbot/scenario/:id' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <ChatbotScenarioPage />
						: <Navigate to='/dashboard' />
				}/>
				<Route path="/users" element={
					user.status.authenticated && userIsAdmin(user.data)
						? <LeftNavBar page={<UserAdminPage/>} />
						: <Navigate to='/' />
				}/>
				<Route path='/create_user' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<CreateUserPage />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/user_profile' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<UserPage />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/patients' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<PatientsListPage />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/patients/:id' element={
					user.status.authenticated && userIsAdminOrSlp(user.data)
						? <LeftNavBar page={<PatientEditPage />} />
						: <Navigate to='/dashboard' />
				}/>
				<Route path='/' element={
					user.status.authenticated && !userIsAdminOrSlp(user.data)
						? <>
							<PracticePage />
							{!isChrome && !isFirefox && !isEdge && !isSafari
								? <BrowserPopup
									handleBrowserPopup={handleBrowserPopup}
								/>
								: <></>
							}
							{ showConsentPopup() // if consent has been given, do not ask it again
								? <Consent />
								: <></>
							}
						</>
						: <Navigate to='/login' />
				}/>
				<Route path='/exercise/production/:id/:format' element={
					user.status.authenticated
						? <ExercisePage consent={cookieConsent} mixpanel={mixpanel} />
						: <Navigate to='/' />
				}/>
				<Route path='/exercise/comprehension/:id/:format' element={
					user.status.authenticated
						? <ComprehensionExercisePage consent={cookieConsent} mixpanel={mixpanel} />
						: <Navigate to='/' />
				}/>
				<Route path='/cookies' element={
					<>
						<CookiesPage />
					</>
				}/>
				<Route path="*" element={ <Navigate to='/' /> }/>
			</>
		)
	}

	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onError={myErrorHandler}>
			<Suspense fallback={<Loading />}>
				<Routes>
					{pageContent()}
				</Routes>
			</Suspense>
		</ErrorBoundary>
	)
}

export default App
